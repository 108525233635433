<template>
    <form-modal
        ref="formModal"
        :v="$v.modalProductCategory"
        :title="i18nTitleModal"
        :in-editing="isEdit"
        size="xl"
        :handle-close="handleCancel"
    >
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nProductCategory"
                    :operation="isEdit ? modalOperation : 'deleted'"
                />
                <feedback-modal
                    ref="feedbackModal"
                    :title="i18nProductCategory"
                />
                <b-row v-if="!isEdit" class="mb-2">
                    <div class="col-sm-6 form-inline"> </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <edit-label-button
                                v-if="$can('UPDATE', 'PRODUCTCATEGORY')"
                                :title="
                                    getI18n(i18nKey, 'TITLES.product_category')
                                "
                                :disabled="deleteLoading"
                                @onClick="isEdit = true"
                            />
                            <delete-label-button
                                v-if="$can('DELETE', 'PRODUCTCATEGORY')"
                                :title="
                                    getI18n(i18nKey, 'TITLES.product_category')
                                "
                                :busy="deleteLoading"
                                @onClick="handleDelete"
                            />
                        </div>
                    </div>
                </b-row>
                <b-row>
                    <b-col class="col-sm-12 col-md-4">
                        <b-form-group
                            :label="i18nName"
                            class="required label-pdv"
                            label-for="modal-name"
                            :invalid-feedback="
                                getI18n('ERROR_CODES.invalid_name')
                            "
                        >
                            <b-form-input
                                id="modal-name"
                                v-model.trim="
                                    $v.modalProductCategory.name.$model
                                "
                                class="input-pdv-blue"
                                name="name"
                                aria-describedby="input-1-live-feedback"
                                type="text"
                                :state="
                                    validateField(
                                        'name',
                                        'modalProductCategory'
                                    )
                                "
                                autofocus
                                autocomplete="off"
                                :disabled="!isEdit || submitLoading"
                                @blur="$v.modalProductCategory.name.$touch"
                            />
                        </b-form-group>
                        <b-row v-if="isEdit">
                            <b-col md="10">
                                <b-form-group
                                    :label="i18nProductSubcategory"
                                    class="label-pdv"
                                    label-for="modal-name"
                                >
                                    <b-form-input
                                        id="modal-name"
                                        ref="subCategoryName"
                                        v-model.trim="subCategoryName"
                                        class="input-pdv-blue"
                                        name="name"
                                        aria-describedby="input-1-live-feedback"
                                        type="text"
                                        autocomplete="off"
                                        :disabled="submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="2" class="d-flex">
                                <b-button
                                    class="
                                        btn btn-pdv-gradient-green
                                        align-self-center
                                    "
                                    @click="handleAddSubcategory"
                                >
                                    <i class="fe-plus" />
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col class="col-sm-12 col-md-8">
                        <data-mode-vuetable
                            ref="relationVuetable"
                            v-model="modalProductCategory.children"
                            :fields="fields"
                            :display-delete-button="
                                $can('DELETE', 'PRODUCTCATEGORY') && isEdit
                            "
                            :busy="submitLoading"
                            @onClickDelete="handleRemoveSubcategorie"
                        />
                    </b-col>
                </b-row>
            </form>
        </template>
        <template v-slot:footer>
            <div v-if="isEdit" class="d-flex justify-content-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <save-button
                    v-if="isProductCategoryCreated"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
                <add-button
                    v-else
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

import ProductCategoriesService from '@src/services/ProductCategoriesService'

import FormModal from '@components/FormModal'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import validationMixin from '@src/mixins/validation'
import swalFeedback from '@src/mixins/swalFeedback'
import DataModeVuetable from '@/src/components/DataModeVuetable.vue'
import CancelButton from '@/src/components/CancelButton'
import AddButton from '@/src/components/AddButton'
import SaveButton from '@/src/components/SaveButton'
import EditLabelButton from '@/src/components/EditLabelButton.vue'
import DeleteLabelButton from '@/src/components/DeleteLabelButton.vue'
import cloneObject from '@utils/clone-object'

const i18nCommon = 'COMMON'
const i18nKey = 'PRODUCT_CATEGORIES'

export default {
    components: {
        FormModal,
        ConfirmationFormModal,
        FeedbackModal,
        DataModeVuetable,
        CancelButton,
        AddButton,
        SaveButton,
        EditLabelButton,
        DeleteLabelButton,
    },
    mixins: [validationMixin, swalFeedback],
    data() {
        return {
            i18nCommon,
            i18nKey,
            modalProductCategory: {},
            isEdit: false,
            submitLoading: false,
            deleteLoading: false,
            fields: [
                {
                    key: 'name',
                    label: this.i18nProductSubcategory,
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: '',
                },
            ],
            subCategoryName: '',
        }
    },
    validations: {
        modalProductCategory: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(255),
            },

            parent_id: {},
        },
    },
    computed: {
        isProductCategoryCreated() {
            return this.modalProductCategory.id > 0
        },
        modalOperation() {
            return this.isProductCategoryCreated ? 'edited' : 'created'
        },
        i18nTitleModal() {
            if (this.isProductCategoryCreated) {
                if (this.isEdit) {
                    return `${this.getI18n(i18nCommon, 'edit')} ${
                        this.i18nProductCategory
                    }`
                }
                return this.modalProductCategory.name
            } else {
                return this.getI18n(i18nKey, 'BUTTONS.new_product_category')
            }
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nProductCategory() {
            return this.getI18n(i18nCommon, 'category')
        },
        i18nProductSubcategory() {
            return this.getI18n(i18nKey, 'TITLES.parent_category')
        },
    },
    methods: {
        normalizer(node) {
            return {
                label: node.name,
                isDisabled:
                    (node.products ? node.products.length > 0 : false) ||
                    node.id === this.modalProductCategory.parent_id,
            }
        },
        showModal(data) {
            this.submitLoading = false
            this.deleteLoading = false

            if (data) {
                this.isEdit = false
                this.modalProductCategory = cloneObject({
                    ...data,
                    children: data.children ?? [],
                    products: data.products ?? [],
                });
            } else {
                this.isEdit = true
                this.modalProductCategory = {
                    id: 0,
                    name: '',
                    parent_id: null,
                    old_parent_id: null,
                    parent_name: '',
                    children: [],
                    products: [],
                };
            }

            this.$refs.formModal.show()
            this.resetValidation()
        },

        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
        },

        handleCancel() {
            if (this.isEdit) {
                this.$refs.confirmationFormModal.showModal(
                    true,
                    this.handleClose
                )
            } else {
                this.handleClose(true)
            }
        },

        handleClose(close) {
            if (!close) {
                return
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },

        handleOk() {
            this.$v.modalProductCategory.$touch()
            if (!this.$v.modalProductCategory.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(
                    false,
                    this.handleSubmit
                )
            }
        },

        async handleSubmit(submit) {
            if (!submit) {
                return
            }

            this.submitLoading = true

            const operation = this.isProductCategoryCreated
                ? ProductCategoriesService.update(
                      this.modalProductCategory.id,
                      this.modalProductCategory
                  )
                : ProductCategoriesService.create(this.modalProductCategory)

            const response = await operation
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(
                    this.i18nProductCategory,
                    this.modalOperation
                )
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            }

            this.submitLoading = false
        },
        handleDelete() {
            if (this.deleteLoading) {
                return;
            }

            this.$refs.confirmationFormModal.showModal(false, this.deleteProductCategorie)
        },
        async deleteProductCategorie(deleteRecord) {
            if (!deleteRecord) {
                return
            }

            this.$v.modalProductCategory.$touch()
            this.deleteLoading = true

            const response = await ProductCategoriesService.delete(this.modalProductCategory.id)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nProductCategory, 'deleted', 3)
            } else if (statusCode === '417') {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            } else if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }

            this.deleteLoading = false
        },
        async handleAddSubcategory(name) {
            if (this.subCategoryName) {
                const index = this.modalProductCategory.children.findIndex(
                    (find) =>
                        find.name.trim().toUpperCase() ===
                        this.subCategoryName.trim().toUpperCase()
                )

                if (index < 0) {
                    this.modalProductCategory.children.push({
                        id: 0,
                        name: this.subCategoryName,
                    })
                }

                this.subCategoryName = ''
                this.$refs.subCategoryName.focus()
            }
        },
        async handleRemoveSubcategorie(id) {
            const index = this.modalProductCategory.children.findIndex(
                (find) => find.id === id
            )

            if (index > -1) {
                this.modalProductCategory.children.splice(index, 1)
            }
        },
    },
}
</script>
