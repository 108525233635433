/* eslint-disable vue/camelcase */
<script>
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue';
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import ProductsService from '@src/services/ProductsService'
import Layout from '@layouts/main'
import productsModal from '@views/products/productsModal'
import productFilterModal from '@views/products/productFilterModal'
import pagination from '@src/mixins/pagination'
import swalFeedback from '@src/mixins/swalFeedback'
import filterVuetable from '@src/mixins/filterVuetable'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import FilterBar from '@src/components/FilterBar'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import importModal from '@views/importJob/importJobModal'
import AddLabelButton from '@/src/components/AddLabelButton.vue'
import ImportButton from '@/src/components/ImportButton.vue'
import ExportButton from '@/src/components/ExportButton.vue'
import FilterLabelButton from '@/src/components/FilterLabelButton.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import DeleteAllButton from '@/src/components/DeleteAllButton.vue'
import DeleteSelectedButton from '@/src/components/DeleteSelectedButton.vue'
import productFields from '@src/fields/productFields'
import { debounce } from 'lodash'

const i18nCommon = 'COMMON'
const i18nKey = 'PRODUCTS'

export default {
    components: {
        ApiModeVuetable,
        Breadcrumb,
        PageHeader,
        Layout,
        productsModal,
        productFilterModal,
        FilterBar,
        ConfirmationFormModal,
        FeedbackModal,
        importModal,
        AddLabelButton,
        ImportButton,
        ExportButton,
        FilterLabelButton,
        MoreTableButton,
        DeleteAllButton,
        DeleteSelectedButton,
    },
    mixins: [
        swalFeedback,
        pagination,
        filterVuetable,
        vuetableFormatters,
        productFields,
    ],
    data() {
        return {
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            i18nCommon,
            i18nKey,
            additionalParameters: {
                with: ['category', 'brand', 'thumbnailFile'],
            },
            deleteType: 'delete_selected',
            deleteSelectedLoading: false,
            deleteAllLoading: false,
            modalIsEdit: false,
            modal: {
                id: 0,
                name: '',
                is_competitor: false,
                brand_id: null,
                brand: {
                    id: null,
                    name: '',
                },
                category: {
                    id: null,
                    name: '',
                },
                product_category_id: null,
                min_price: 0,
                max_price: 0,
                reference: '',
                bar_code: '',
                due_date: '',
                thumbnailFile: {},
                thumbnail_file_id: {},
                is_category_leader: false,
                active: true,
                selectedFile: null,
            },
            data: [],
            perPage: 10,
            css: {},
            filterText: '',
            filters: {
                brands: [],
                product_category_id: null,
                isCompetitor: false,
                minPriceFrom: 0,
                minPriceTo: 0,
                maxPriceFrom: 0,
                maxPriceTo: 0,
                dueDate: null,
                isCategoryLeader: false,
                active: false,
            },
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.product',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.product',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nNewProduct() {
            return this.getI18n(i18nKey, 'BUTTONS.new_product')
        },
        i18nProducts() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.product',
                modifier: 2,
            })
        },
    },

    watch: {
        filterText: debounce(function (searchText) {
            this.additionalParameters = {
                with: ['category', 'brand', 'thumbnailFile'],
                search: '',
            }
            if (searchText && searchText.length > 0) {
                this.additionalParameters.search = `name:${searchText};reference:${searchText};brand.name:${searchText};category.name:${searchText}`
            }
            this.refreshTable()
        }, 1000),
    },
    methods: {
        fetch(url, params) {
            return ProductsService.fetchVuetable(url, params)
        },
        createAdditionalParameters() {
            const search = this.createSearch({
                due_date: this.filters.dueDate,
                is_competitor: this.filters.isCompetitor ? String(this.filters.isCompetitor.value) : null,
                active: this.filters.active ? String(this.filters.active.value) : null,
                is_category_leader: this.filters.isCategoryLeader ? String(this.filters.isCategoryLeader.value) : null,
            })

            return this.formatParameters({
                with: ['category', 'brand', 'thumbnailFile'],
                search,
                product_category_id: this.filters.product_category_id,
                brand_id: this.filters.brands.map((brand) => brand.id),
                min_price: this.formatFromTo(
                    this.filters.minPriceFrom,
                    this.filters.minPriceTo
                ),
                max_price: this.formatFromTo(
                    this.filters.maxPriceFrom,
                    this.filters.maxPriceTo
                ),
                searchJoin: search.search(';') > 0 ? 'and' : '',
            })
        },

        cleanModal() {
            this.replaceModalData({
                id: 0,
                brand_id: null,
                brand: {
                    id: null,
                    name: '',
                },
                category: {
                    id: null,
                    name: '',
                    parent_id: null,
                },
                thumbnailFile: null,
                thumbnail_file_id: null,
                product_category_id: null,
                name: '',
                is_competitor: false,
                min_price: '',
                max_price: '',
                reference: '',
                bar_code: '',
                due_date: '',
                is_category_leader: false,
                active: true,
                selectedFile: null,
            })
        },

        showModal() {
            this.$nextTick(() => this.$refs.productModal.showModal())
        },

        showImportModal() {
            this.$nextTick(() => this.$refs.importModal.showModal())
        },

        openModalToCreate() {
            this.modalIsEdit = true
            this.cleanModal()
            this.showModal()
        },

        openModalToEdit(product) {
            this.modalIsEdit = false
            this.replaceModalData(product)
            this.showModal()
        },

        replaceModalData(data) {
            Object.assign(this.modal, data)

            this.modal.selectedFile = null
        },

        reloadTable() {
            this.$refs.vuetable.reload()
        },

        refreshTable() {
            this.$refs.vuetable.refresh()
        },

        filter(appliedFilters) {
            this.filters = appliedFilters
            this.additionalParameters = this.createAdditionalParameters()
            this.refreshTable()
        },

        showFilterModal() {
            this.$nextTick(() =>
                this.$refs.productFilterModal.showModal(this.filters)
            )
        },

        handleDelete(type) {
            if (type === 'delete_selected' && this.deleteSelectedLoading) {
                return
            } else if (type === 'delete_all' && this.deleteAllLoading) {
                return
            }

            this.deleteType = type
            if (type === 'delete_selected') {
                this.$refs.confirmationFormModal.showModal(false, this.deletedSelected)
            }
            else if (type === 'delete_all') {
                this.$refs.confirmationFormModal.showModal(false, this.deletedAllFiltered)
            }
        },

        async deletedSelected(deleteRecord) {
            if (!deleteRecord) {
                return
            }

            this.deleteSelectedLoading = true
            await this.deleteProducts(
                ProductsService.deleteMultiple({
                    product_id: this.$refs.vuetable.$refs.vuetable.selectedTo,
                })
            )
        },

        async deletedAllFiltered(deleteRecord) {
            if (!deleteRecord) {
                return
            }

            this.deleteAllLoading = true
            await this.deleteProducts(
                ProductsService.deleteMultiple(
                    this.additionalParameters
                )
            )
        },

        async deleteProducts(deleteFunction) {
            const response = await deleteFunction
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$refs.feedbackModal.showModal(statusCode, response.data)
                this.refreshTable()
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }
            this.deleteSelectedLoading = false
            this.deleteAllLoading = false
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-6 form-inline">
                    <filter-bar v-model="filterText"></filter-bar>
                    <filter-label-button @onClick="showFilterModal" />
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-right">
                        <add-label-button
                            v-if="$can('CREATE', 'BRAND')"
                            :title="i18nNewProduct"
                            @onClick="openModalToCreate"
                        />
                        <import-button
                            v-if="$can('CREATE', 'IMPORTJOB')"
                            @onClick="showImportModal"
                        />
                        <export-button
                            v-if="$can('CREATE', 'EXPORTJOB')"
                            type="product"
                            :create-parameters="createAdditionalParameters"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <b-row>
                    <div class="col-sm-6">
                        <PageHeader :title="i18nProducts" />
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <delete-selected-button
                                v-if="$can('DELETE', 'PRODUCT')"
                                :busy="deleteSelectedLoading"
                                :disabled="deleteAllLoading"
                                @onClick="handleDelete('delete_selected')"
                            />
                            <delete-all-button
                                v-if="$can('DELETE', 'PRODUCT')"
                                :busy="deleteAllLoading"
                                :disabled="deleteSelectedLoading"
                                @onClick="handleDelete('delete_all')"
                            />
                        </div>
                    </div>
                </b-row>
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="productFields"
                        :per-page="perPage"
                        :display-selection-records-per-page="true"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="status" slot-scope="props">
                            <span
                                class="badge"
                                :class="{
                                    'bg-soft-success text-success':
                                        props.rowData.status ===
                                        'Active',
                                    'bg-soft-danger text-danger':
                                        props.rowData.status ===
                                        'Blocked',
                                }"
                                >{{ props.rowData.status }}</span
                            >
                        </template>
                        <template slot="photo" slot-scope="props">
                            <a v-if="props.rowData.thumbnailFile && props.rowData.thumbnailFile.url"
                                :href="props.rowData.thumbnailFile.url"
                                target="_blank">
                                <i class="fe-eye"/>
                            </a>
                            <i v-else class="fe-eye-off" />
                        </template>
                        <template slot="actions" slot-scope="props">
                            <div class="table-button-container">
                                <more-table-button @onClick="openModalToEdit(props.rowData)" />
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <confirmation-form-modal
            ref="confirmationFormModal"
            :title="i18nProducts"
            :operation="deleteType"
        />
        <feedback-modal
            ref="feedbackModal"
            :title="i18nProducts"
            :operation="deleteType"
        />
        <products-modal
            ref="productModal"
            :modal="modal"
            :modal-is-edit="modalIsEdit"
            @refresh="reloadTable"
        />
        <import-modal
            ref="importModal"
            :csv-type="'product'"
            :title="
                getI18nModified({
                    prefix: 'IMPORT_JOBS',
                    suffix: 'TITLES.product',
                    modifier: 2,
                })
            "
        />
        <product-filter-modal
            ref="productFilterModal"
            :handle-submit="filter"
        />
    </Layout>
</template>
