<template>
    <form-modal
        ref="formModal"
        :title="i18nTitleModal"
        :in-editing="isEdit"
        size="xl"
        :handle-close="handleCancel"
    >
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <brand-modal
                    ref="brandModal"
                    @refresh="reloadBrandOptions"
                />
                <product-categories-modal
                    ref="productCategoriesModal"
                    @refresh="reloadCategoryOptions"
                />
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nProduct"
                    :operation="isEdit ? modalOperation : 'deleted'"
                />
                <feedback-modal
                    ref="feedbackModal"
                    :title="i18nProduct"
                />
                <b-row v-if="!isEdit" class="mb-2">
                    <div class="col-sm-6 form-inline"> </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <edit-label-button
                                v-if="$can('UPDATE', 'PRODUCT')"
                                :title="getI18n(i18nKey, 'TITLES.product')"
                                :disabled="deleteLoading"
                                @onClick="isEdit = true"
                            />
                            <delete-label-button
                                v-if="$can('DELETE', 'PRODUCT')"
                                :title="getI18n(i18nKey, 'TITLES.product')"
                                :busy="deleteLoading"
                                @onClick="handleDelete"
                            />
                        </div>
                    </div>
                </b-row>
                <b-row>
                    <b-col md="9">
                        <b-row>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nReference"
                                    label-for="modal-reference"
                                    :invalid-feedback="
                                        getI18n('ERROR_CODES.invalid_code')
                                    "
                                    class="required label-pdv"
                                >
                                    <b-form-input
                                        id="modal-reference"
                                        v-model.trim="
                                            $v.modalProduct.reference.$model
                                        "
                                        name="reference"
                                        class="input-pdv-blue"
                                        aria-describedby="input-1-live-feedback"
                                        type="text"
                                        :state="
                                            validateField(
                                                'reference',
                                                'modalProduct'
                                            )
                                        "
                                        autofocus
                                        :disabled="!isEdit || submitLoading"
                                        autocomplete="off"
                                        @blur="$v.modalProduct.reference.$touch"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="9">
                                <b-form-group
                                    :label="i18nName"
                                    label-for="modal-name"
                                    :invalid-feedback="
                                        getI18n('ERROR_CODES.invalid_name')
                                    "
                                    class="required label-pdv"
                                >
                                    <b-form-input
                                        id="modal-name"
                                        v-model.trim="
                                            $v.modalProduct.name.$model
                                        "
                                        name="name"
                                        class="input-pdv-blue"
                                        aria-describedby="input-1-live-feedback"
                                        type="text"
                                        :state="
                                            validateField(
                                                'name',
                                                'modalProduct'
                                            )
                                        "
                                        :disabled="!isEdit || submitLoading"
                                        autocomplete="off"
                                        @blur="$v.modalProduct.name.$touch"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="6">
                                <label class="label-pdv">
                                    {{ i18nBrand }} *
                                </label>
                                <a
                                    v-if="$can('CREATE', 'BRAND') && isEdit"
                                    href="javascript: void(0);"
                                    class="newBrandModal"
                                    :title="i18nNewBrand"
                                    @click="showBrandModal"
                                >
                                    <i class="fe-plus-circle icon-green ml-1" />
                                </a>
                                <multiSelectWithService
                                    :id="'modal-brand'"
                                    ref="brandMultiselect"
                                    v-model="modalProduct.brand"
                                    :input-function="$v.modalProduct.brand_id.$touch"
                                    :select-class="validationClass($v.modalProduct.brand_id)"
                                    :service="'brands'"
                                    :searchable="true"
                                    :multiple="false"
                                    :disabled="!isEdit || submitLoading"
                                />
                                <label v-if="$v.modalProduct.brand_id.$dirty && $v.modalProduct.brand_id.$invalid" class="label-pdv-invalid">
                                    {{ getI18n('ERROR_CODES.invalid_brand') }}
                                </label>
                            </b-col>
                            <b-col md="6">
                                <label class="label-pdv">
                                    {{ i18nProductCategory }} *
                                </label>
                                <a
                                    v-if="$can('CREATE', 'PRODUCTCATEGORY') && isEdit"
                                    href="javascript: void(0);"
                                    class="newProductCategoryModal"
                                    :title="i18nNewProductCategory"
                                    @click="showProductCategoryModal"
                                >
                                    <i class="fe-plus-circle icon-green ml-1" />
                                </a>
                                <treeSelectWithService
                                    ref="productCategoryTreeselect"
                                    v-model="modalProduct.product_category_id"
                                    :input-function="$v.modalProduct.product_category_id.$touch"
                                    :select-class="validationClass($v.modalProduct.product_category_id)"
                                    :option="{ id: null, name: '' }"
                                    :async-search="false"
                                    :service="'product-categories'"
                                    :disable-branch-nodes="true"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_category')"
                                    :required="true"
                                    :no-children-text="getI18n('PRODUCT_CATEGORIES.INFO','no_subcategories_text')"
                                    :parameters="parameters"
                                    :disabled="!isEdit || submitLoading"
                                />
                                <label v-if="$v.modalProduct.brand_id.$dirty && $v.modalProduct.brand_id.$invalid" class="label-pdv-invalid">
                                    {{ getI18n('ERROR_CODES.invalid_category') }}
                                </label>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nBarCode"
                                    label-for="modal-bar-code"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_bar_code')"
                                    class="label-pdv"
                                >
                                    <b-form-input
                                        id="modal-bar-code"
                                        v-model.trim="$v.modalProduct.bar_code.$model"
                                        name="bar_code"
                                        class="input-pdv-blue"
                                        aria-describedby="input-1-live-feedback"
                                        :state="validateField('bar_code', 'modalProduct')"
                                        :disabled="!isEdit || submitLoading"
                                        autocomplete="off"
                                        @blur="$v.modalProduct.bar_code.$touch"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nDueDate"
                                    label-for="modal-due_date"
                                    :invalid-feedback="
                                        getI18n('ERROR_CODES.invalid_due_date')
                                    "
                                    class="label-pdv"
                                >
                                    <b-form-input
                                        id="modal-due_date"
                                        v-model.trim="
                                            $v.modalProduct.due_date.$model
                                        "
                                        name="due_date"
                                        class="input-pdv-blue"
                                        aria-describedby="input-1-live-feedback"
                                        type="text"
                                        :state="
                                            validateField(
                                                'due_date',
                                                'modalProduct'
                                            )
                                        "
                                        autocomplete="off"
                                        :disabled="!isEdit || submitLoading"
                                        @blur="$v.modalProduct.due_date.$touch"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nMinPrice"
                                    label-for="modal-min-price"
                                    :invalid-feedback="
                                        getI18n('ERROR_CODES.invalid_price')
                                    "
                                    class="required label-pdv"
                                >
                                    <money
                                        id="modal-min-price"
                                        v-model="modalProduct.min_price"
                                        v-bind="money"
                                        name="min_price"
                                        class="form-control input-pdv-blue"
                                        :class="
                                            validationClass(
                                                $v.modalProduct.min_price
                                            )
                                        "
                                        aria-describedby="input-1-live-feedback"
                                        type="text"
                                        :state="
                                            validateField(
                                                'min_price',
                                                'modalProduct'
                                            )
                                        "
                                        :disabled="!isEdit || submitLoading"
                                        @blur.native="
                                            $v.modalProduct.min_price.$touch
                                        "
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nMaxPrice"
                                    label-for="modal-max-price"
                                    :invalid-feedback="
                                        getI18n('ERROR_CODES.invalid_price')
                                    "
                                    class="required label-pdv"
                                >
                                    <money
                                        id="modal-max-price"
                                        v-model="
                                            $v.modalProduct.max_price.$model
                                        "
                                        v-bind="money"
                                        name="max_price"
                                        class="form-control input-pdv-blue"
                                        aria-describedby="input-1-live-feedback"
                                        type="text"
                                        :class="
                                            validationClass(
                                                $v.modalProduct.max_price
                                            )
                                        "
                                        :state="
                                            validateField(
                                                'max_price',
                                                'modalProduct'
                                            )
                                        "
                                        :disabled="!isEdit || submitLoading"
                                        @blur.native="
                                            $v.modalProduct.max_price.$touch
                                        "
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="2">
                                <b-form-group
                                    :label="i18nActive"
                                    label-for="modal-active"
                                    class="label-pdv"
                                >
                                    <b-form-checkbox
                                        id="modal-active"
                                        v-model="modalProduct.active"
                                        name="is_active"
                                        size="lg"
                                        switch
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    :label="i18nCategoryLeader"
                                    label-for="modal-category-leader"
                                    class="label-pdv"
                                >
                                    <b-form-checkbox
                                        id="modal-category-leader"
                                        v-model="
                                            modalProduct.is_category_leader
                                        "
                                        name="is_category_leader"
                                        size="lg"
                                        switch
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="2">
                                <b-form-group
                                    :label="i18nCompetitor"
                                    label-for="modal-competitor"
                                    class="label-pdv"
                                >
                                    <b-form-checkbox
                                        id="modal-competitor"
                                        v-model="modalProduct.is_competitor"
                                        name="is_competitor"
                                        size="lg"
                                        switch
                                        :disabled="!isEdit || submitLoading"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col md="3">
                        <b-row>
                            <b-col>
                                <div class="imagePreDivProduct">
                                    <div>
                                        <label class="label-pdv">
                                            {{ i18nPhoto }}
                                        </label>
                                        <a
                                            v-if="isEdit && modalProduct && imageProduct && imageProduct.url"
                                            href="javascript: void(0);"
                                            class="removeImage"
                                            @click="removeImage"
                                        >
                                            <i class="fe-trash-2 ml-1" />
                                        </a>
                                    </div>
                                    <div class="imageDivProduct d-flex">
                                        <img
                                            v-if="imageProduct && imageProduct.url"
                                            :src="imageProduct.url"
                                            fluid
                                            class="m-auto"
                                            alt="Product Image"
                                        />
                                        <img
                                            v-else
                                            src="@assets/images/no-image.png"
                                            fluid
                                            class="m-auto"
                                            alt="No Image Upload"
                                        />
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row v-if="isEdit" class="mt-4">
                            <b-col>
                                <b-form-group
                                    label-for="logoFile"
                                    :invalid-feedback="
                                        sizeLargerThanAllowed ?
                                        getI18n('ERROR_CODES', 'file_must_be_up_to_1mb') :
                                        getI18n('ERROR_CODES', 'invalid_file')
                                    "
                                    class="w-100"
                                >
                                    <b-form-file
                                        ref="logoFile"
                                        v-model="$v.modalProduct.selectedFile.$model"
                                        required
                                        type="file"
                                        accept=".jpg, .png, .jpeg"
                                        :browse-text="getI18n('COMMON', 'browse')"
                                        :placeholder="getI18n('COMMON', 'no_file_chosen')"
                                        :state="validateField('selectedFile', 'modalProduct')"
                                        @blur="$v.modalProduct.selectedFile.$touch"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </form>
        </template>
        <template v-slot:footer>
            <div v-if="isEdit" class="d-flex justify-content-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <save-button
                    v-if="isProductCreated"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
                <add-button
                    v-else
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import {
    required,
    minLength,
    maxLength,
    decimal,
    numeric,
} from 'vuelidate/lib/validators'
import { Money } from 'v-money'

import ProductsService from '@src/services/ProductsService'
import FilesService from '@src/services/FilesService'
import FormModal from '@components/FormModal'
import brandModal from '@views/brands/brandModal'
import productCategoriesModal from '@views/productCategories/productCategoriesModal'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import multiSelectWithService from '@src/components/multiSelectWithService'
import treeSelectWithService from '@src/components/treeSelectWithService'
import validationMixin from '@src/mixins/validation'
import swalFeedback from '@src/mixins/swalFeedback'
import { i18n } from '@src/i18n'
import CancelButton from '@/src/components/CancelButton'
import AddButton from '@/src/components/AddButton'
import SaveButton from '@/src/components/SaveButton'
import EditLabelButton from '@/src/components/EditLabelButton.vue'
import DeleteLabelButton from '@/src/components/DeleteLabelButton.vue'

const i18nCommon = 'COMMON'
const i18nKey = 'PRODUCTS'

const isTrueImage = (value, model) => {
    if (!value || !value.type) {
        return true
    }
    return value.type.startsWith('image')
}

const isSizeValid = (value, model) => {
    if (!value || !value.size) {
        return true
    }
    return value.size <= 1048576
}

export default {
    components: {
        FormModal,
        brandModal,
        productCategoriesModal,
        ConfirmationFormModal,
        FeedbackModal,
        Money,
        multiSelectWithService,
        treeSelectWithService,
        CancelButton,
        AddButton,
        SaveButton,
        EditLabelButton,
        DeleteLabelButton,
    },
    mixins: [validationMixin, swalFeedback],
    props: {
        modalIsEdit: {
            required: true,
            type: Boolean,
        },
        modal: {
            type: Object,
            default: () => {
                return {
                    id: '',
                    name: '',
                    brand_id: null,
                    brand: {
                        id: null,
                        name: '',
                    },
                    category: {
                        id: null,
                        name: '',
                        parent_id: null,
                    },
                    thumbnailFile: null,
                    thumbnail_file_id: null,
                    product_category_id: null,
                    is_competitor: false,
                    min_price: '',
                    max_price: '',
                    reference: '',
                    due_date: '',
                    is_category_leader: false,
                    active: true,
                    selectedFile: null,
                }
            },
        },
    },
    data() {
        return {
            i18nCommon,
            i18nKey,
            categoryId: null,
            parameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            money: {
                decimal: i18n.t('CURRENCY.decimal'),
                thousands: i18n.t('CURRENCY.thousands'),
                prefix: i18n.t('CURRENCY.prefix'),
                suffix: '',
                precision: 2,
                masked: false,
            },
            modalProduct: {
                product_category_id: this.modal.category.id,
                ...this.modal,
            },
            isEdit: false,
            submitLoading: false,
            deleteLoading: false,
            imageProduct: {
                url: null,
            },
            sizeLargerThanAllowed: false,
        }
    },
    validations: {
        modalProduct: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(255),
            },

            product_category_id: {
                required,
                numeric,
            },

            brand_id: {
                required,
                numeric,
            },

            min_price: {
                required,
                decimal,
                minLength: minLength(1),
                maxLength: maxLength(11),
                notNegative(value) {
                    return value >= 0
                },
                isLesserOrEqualThanMaxPrice(value, model) {
                    return !model || value <= model.max_price
                },
            },
            max_price: {
                required,
                decimal,
                minLength: minLength(1),
                maxLength: maxLength(11),
                notNegative(value) {
                    return value >= 0
                },
                isGreaterOrEqualThanMinPrice(value, model) {
                    return !model || value >= model.min_price
                },
            },
            reference: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(32),
            },
            bar_code: {
                numeric,
            },
            due_date: {
                numeric,
                minLength: minLength(1),
                maxLength: maxLength(32),
            },
            selectedFile: {
                isTrueImage,
                isSizeValid,
            },
        },
    },
    computed: {
        isProductCreated() {
            return this.modalProduct.id > 0
        },
        i18nTitleModal() {
            if (this.isProductCreated) {
                if (this.isEdit) {
                    return `${this.getI18n(i18nCommon, 'edit')} ${
                        this.i18nProduct
                    }`
                }
                return this.modalProduct.name
            } else {
                return this.getI18n(i18nKey, 'BUTTONS.new_product')
            }
        },

        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },

        i18nPhoto() {
            return this.getI18n(i18nKey, 'FIELDS.photo')
        },

        i18nProduct() {
            return this.getI18n(i18nKey, 'TITLES.product')
        },
        i18nProductCategory() {
            return this.getI18n(i18nKey, 'FIELDS.category')
        },
        i18nNewProductCategory() {
            return this.getI18n('PRODUCT_CATEGORIES', 'BUTTONS.new_product_category')
        },
        i18nCompetitor() {
            return this.getI18n(i18nKey, 'FIELDS.competitor')
        },

        i18nMinPrice() {
            return this.getI18n(i18nKey, 'FIELDS.min_price')
        },

        i18nMaxPrice() {
            return this.getI18n(i18nKey, 'FIELDS.max_price')
        },

        i18nReference() {
            return this.getI18n(i18nKey, 'FIELDS.reference')
        },

        i18nBarCode() {
            return this.getI18n(i18nKey, 'FIELDS.bar_code')
        },

        i18nDueDate() {
            return this.getI18n(i18nKey, 'FIELDS.due_date')
        },

        i18nCategoryLeader() {
            return this.getI18n(i18nKey, 'FIELDS.category_leader')
        },

        i18nActive() {
            return this.getI18n(i18nCommon, 'active')
        },

        i18nNewProduct() {
            return this.getI18n(i18nKey, 'BUTTONS.new_product')
        },

        modalOperation() {
            return this.isProductCreated ? 'edited' : 'created'
        },

        i18nBrand() {
            return this.getI18n('BRANDS', 'TITLES.brand')
        },

        i18nNewBrand() {
            return this.getI18n('BRANDS', 'BUTTONS.new_brand')
        },
    },
    watch: {
        'modalProduct.category': function (newValue) {
            this.modalProduct.product_category_id = newValue
                ? newValue.id
                : null
        },

        'modalProduct.brand': function (newValue) {
            this.modalProduct.brand_id = newValue ? newValue.id : null
        },

        'modalProduct.selectedFile': function (newValue) {
            this.sizeLargerThanAllowed = false
            if (newValue) {
                if (newValue.type.includes('image')) {
                    this.sizeLargerThanAllowed = newValue.size > 1048576
                    this.imageProduct = {
                        url: URL.createObjectURL(newValue),
                    }
                    URL.revokeObjectURL(newValue)
                }
            }
        },
    },
    methods: {
        showModal() {
            this.isEdit = this.modalIsEdit
            this.submitLoading = false
            this.deleteLoading = false
            this.modalProduct = this.modal
            this.imageProduct = this.modalProduct.thumbnailFile
            this.sizeLargerThanAllowed = false
            this.$nextTick(() => this.$refs.formModal.show())
            this.resetValidation()
        },

        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },

        removeImage() {
            this.imageProduct = {}
            this.modalProduct.thumbnailFile = null
            this.modalProduct.thumbnail_file_id = null
            this.modalProduct.selectedFile = null
        },

        cleanModal() {
            this.replaceModalData({
                id: '',
                name: '',
                is_competitor: false,
                brand_id: null,
                brand: {
                    id: null,
                    name: '',
                },
                category: {
                    id: null,
                    name: '',
                    parent_id: null,
                },
                thumbnailFile: null,
                thumbnail_file_id: null,
                product_category_id: null,
                min_price: 0,
                max_price: 0,
                reference: '',
                due_date: '',
                is_category_leader: false,
                active: true,
                selectedFile: null,
            })

            this.$refs.treeselect.clearInput()

            this.resetValidation()
        },

        replaceModalData(data) {
            Object.assign(this.modalProduct, data)
        },

        handleCancel() {
            if (this.isEdit) {
                this.$refs.confirmationFormModal.showModal(
                    true,
                    this.handleClose
                )
            } else {
                this.handleClose(true)
            }
        },

        handleClose(close) {
            if (!close) {
                return
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },

        handleOk() {
            this.$v.modalProduct.$touch()

            if (!this.$v.modalProduct.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },

        async handleSubmit(submit) {
            if (!submit) {
                return
            }

            this.submitLoading = true

            let saveProduct = true

            if (this.modalProduct.selectedFile) {
                const responseImg = await FilesService.upload(
                    this.modalProduct.selectedFile,
                    'product_picture',
                    true
                )
                    .then((responseImg) => responseImg)
                    .catch((error) => {
                        this.warningFeedbackApi(this.i18nProduct, error.data.errors)
                        this.submitLoading = false
                    })

                const statusCodeImg = responseImg.status.toString()
                saveProduct = statusCodeImg.charAt(0) === '2'
                if (statusCodeImg.charAt(0) === '2') {
                    this.imageProduct = responseImg.data.data
                    this.modalProduct.thumbnail_file_id = responseImg.data.data.id
                }
            }

            if (saveProduct) {
                const operation = this.isProductCreated
                    ? ProductsService.update(this.modalProduct.id, this.modalProduct)
                    : ProductsService.create(this.modalProduct)

                const response = await operation
                    .then((response) => response)
                    .catch((error) => error)

                const statusCode = response.status.toString()

                if (statusCode.charAt(0) === '2') {
                    this.$emit('refresh')
                    this.resetValidation()
                    this.$refs.formModal.hide()
                    this.positiveFeedback(this.i18nProduct, this.modalOperation)
                }

                if (statusCode === '417') {
                    const suffix = `ERRORS.${response.data.message}`
                    const operation = this.modalIsEdit ? 'update' : 'create'
                    this.operationUnsuccessful(i18nKey, suffix, operation)
                }

                if (statusCode === '422') {
                    this.modalResponseErrors = response.data.errors
                }
            }
            this.submitLoading = false
        },
        handleDelete() {
            if (this.deleteLoading) {
                return;
            }

            this.$refs.confirmationFormModal.showModal(false, this.deleteProduct)
        },
        async deleteProduct(deleteRecord) {
            if (!deleteRecord) {
                return;
            }

            this.$v.modalProduct.$touch()
            this.deleteLoading = true

            const response = await ProductsService.delete(this.modalProduct.id)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nProduct, 'deleted')
            } else if (statusCode === '417') {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            } else if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }

            this.deleteLoading = false
        },
        showBrandModal() {
            this.$nextTick(() => this.$refs.brandModal.showModal())
        },
        showProductCategoryModal() {
            this.$nextTick(() => this.$refs.productCategoriesModal.showModal())
        },
        reloadBrandOptions() {
            this.$nextTick(() => this.$refs.brandMultiselect.refresh())
        },
        reloadCategoryOptions() {
            this.$nextTick(() => this.$refs.productCategoryTreeselect.refresh())
        },
    },
}
</script>
<style>
.imagePreDivProduct {
    width: 100%;
    height: 200px;
}
.imageDivProduct {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 200px;
    border: 1px solid #5899ef;
    box-sizing: border-box;
    border-radius: 12px;
}
.imageDivProduct > img {
    max-width: 100%;
    max-height: 100%;
}
.removeImage {
    text-decoration: none;
    color: #fd4084;
}
</style>
