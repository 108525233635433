var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"col-12"},[_c('Breadcrumb',{attrs:{"items":_vm.items}}),_c('div',{staticClass:"row mt-3 mb-3"},[_c('div',{staticClass:"col-sm-6 form-inline"},[_c('filter-bar',{model:{value:(_vm.filterText),callback:function ($$v) {_vm.filterText=$$v},expression:"filterText"}}),_c('filter-label-button',{on:{"onClick":_vm.showFilterModal}})],1),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"text-sm-right"},[(_vm.$can('CREATE', 'BRAND'))?_c('add-label-button',{attrs:{"title":_vm.i18nNewProduct},on:{"onClick":_vm.openModalToCreate}}):_vm._e(),(_vm.$can('CREATE', 'IMPORTJOB'))?_c('import-button',{on:{"onClick":_vm.showImportModal}}):_vm._e(),(_vm.$can('CREATE', 'EXPORTJOB'))?_c('export-button',{attrs:{"type":"product","create-parameters":_vm.createAdditionalParameters}}):_vm._e()],1)])]),_c('div',{staticClass:"card card-pdv"},[_c('b-row',[_c('div',{staticClass:"col-sm-6"},[_c('PageHeader',{attrs:{"title":_vm.i18nProducts}})],1),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"text-sm-right"},[(_vm.$can('DELETE', 'PRODUCT'))?_c('delete-selected-button',{attrs:{"busy":_vm.deleteSelectedLoading,"disabled":_vm.deleteAllLoading},on:{"onClick":function($event){return _vm.handleDelete('delete_selected')}}}):_vm._e(),(_vm.$can('DELETE', 'PRODUCT'))?_c('delete-all-button',{attrs:{"busy":_vm.deleteAllLoading,"disabled":_vm.deleteSelectedLoading},on:{"onClick":function($event){return _vm.handleDelete('delete_all')}}}):_vm._e()],1)])]),_c('div',{staticClass:"card-body"},[_c('api-mode-vuetable',{ref:"vuetable",attrs:{"fetch-data":_vm.fetch,"fields":_vm.productFields,"per-page":_vm.perPage,"display-selection-records-per-page":true,"additional-parameters":_vm.additionalParameters,"sort-order":_vm.sortOrder},scopedSlots:_vm._u([{key:"status",fn:function(props){return [_c('span',{staticClass:"badge",class:{
                                'bg-soft-success text-success':
                                    props.rowData.status ===
                                    'Active',
                                'bg-soft-danger text-danger':
                                    props.rowData.status ===
                                    'Blocked',
                            }},[_vm._v(_vm._s(props.rowData.status))])]}},{key:"photo",fn:function(props){return [(props.rowData.thumbnailFile && props.rowData.thumbnailFile.url)?_c('a',{attrs:{"href":props.rowData.thumbnailFile.url,"target":"_blank"}},[_c('i',{staticClass:"fe-eye"})]):_c('i',{staticClass:"fe-eye-off"})]}},{key:"actions",fn:function(props){return [_c('div',{staticClass:"table-button-container"},[_c('more-table-button',{on:{"onClick":function($event){return _vm.openModalToEdit(props.rowData)}}})],1)]}}])})],1)],1)],1),_c('confirmation-form-modal',{ref:"confirmationFormModal",attrs:{"title":_vm.i18nProducts,"operation":_vm.deleteType}}),_c('feedback-modal',{ref:"feedbackModal",attrs:{"title":_vm.i18nProducts,"operation":_vm.deleteType}}),_c('products-modal',{ref:"productModal",attrs:{"modal":_vm.modal,"modal-is-edit":_vm.modalIsEdit},on:{"refresh":_vm.reloadTable}}),_c('import-modal',{ref:"importModal",attrs:{"csv-type":'product',"title":_vm.getI18nModified({
                prefix: 'IMPORT_JOBS',
                suffix: 'TITLES.product',
                modifier: 2,
            })}}),_c('product-filter-modal',{ref:"productFilterModal",attrs:{"handle-submit":_vm.filter}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }