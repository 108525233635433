<template>
    <form-modal
        ref="formModal"
        :title="getI18nModified({ prefix: 'COMMON.filter', modifier: 2 })"
        @handleHide="handleHide">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-form-row>
                    <b-col md="12">
                        <treeSelectWithService
                            ref="treeselect"
                            v-model="filterModal.product_category_id"
                            :async-search="false"
                            :option="defaultCategory"
                            :multiple="true"
                            :value-consists-of="valueConsistsOf"
                            :service="'product-categories'"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_category')"
                            :label="i18nCategory"
                            :parameters="parameters"
                        />
                    </b-col>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nBrand"
                            label-for="filter-brand"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-brand'"
                                ref="brandMultiselect"
                                v-model="filterModal.brands"
                                :service="'brands'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nIsCompetitor"
                            label-for="filter-is-competitor"
                            class="label-pdv">
                            <multi-select
                                id="filter-is-competitor"
                                v-model="filterModal.isCompetitor"
                                :options="statuses"
                                track-by="value"
                                label="text"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nIsCategoryLeader"
                            label-for="filter-is-category-leader"
                            class="label-pdv">
                            <multi-select
                                id="filter-is-category-leader"
                                v-model="filterModal.isCategoryLeader"
                                :options="statuses"
                                track-by="value"
                                label="text"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nActive"
                            label-for="filter-active"
                            class="label-pdv">
                            <multi-select
                                id="filter-active"
                                v-model="filterModal.active"
                                :options="statuses"
                                track-by="value"
                                label="text"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nDueDate"
                            label-for="filter-due-date"
                            class="label-pdv">
                            <b-form-input
                                id="filter-due-date"
                                v-model.trim="filterModal.dueDate"
                                name="due-date"
                                type="number"
                                min="0"
                                class="input-pdv-blue"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nMinPriceFrom"
                            label-for="modal-min-price"
                            class="label-pdv"
                        >
                            <money
                                id="filter-min-price-from"
                                v-model="filterModal.minPriceFrom"
                                v-bind="money"
                                name="min-price-from"
                                class="form-control input-pdv-blue"
                                type="text"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nMinPriceTo"
                            label-for="modal-min-price-to"
                            class="label-pdv"
                        >
                            <money
                                id="filter-min-price-to"
                                v-model="filterModal.minPriceTo"
                                v-bind="money"
                                name="min-price-to"
                                class="form-control input-pdv-blue"
                                type="text"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nMaxPriceFrom"
                            label-for="filter-max-price-from"
                            class="label-pdv"
                        >
                            <money
                                id="filter-max-price-from"
                                v-model="filterModal.maxPriceFrom"
                                v-bind="money"
                                name="max-price-from"
                                class="form-control input-pdv-blue"
                                type="text"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nMaxPriceTo"
                            label-for="filter-max-price-to"
                            class="label-pdv"
                        >
                            <money
                                id="filter-max-price-to"
                                v-model="filterModal.maxPriceTo"
                                v-bind="money"
                                name="max-price-to"
                                class="form-control input-pdv-blue"
                                type="text"
                            />
                        </b-form-group>
                    </b-col>
                </b-form-row>
            </form>
        </template>
        <template v-slot:footer>
            <save-button
                ref="okButton"
                :busy="submitLoading"
                :disabled="submitLoading"
                :title="getI18n('ACTION.filter')"
                @onClick="handleOk"
            />
            <clear-filter-button
                @onClick="handleClearFilter"
            />
        </template>
    </form-modal>
</template>

<script>
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import FormModal from '@/src/components/FormModal.vue'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import treeSelectWithService from '@src/components/treeSelectWithService'
import multiSelectWithService from '@src/components/multiSelectWithService'
import multiSelect from '@src/components/multiSelect'
import { Money } from 'v-money'
import SaveButton from '@/src/components/SaveButton'
import ClearFilterButton from '@/src/components/ClearFilterButton'
import cloneObject from '@utils/clone-object'

const i18nKey = 'PRODUCTS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        treeSelectWithService,
        multiSelectWithService,
        multiSelect,
        Money,
        SaveButton,
        ClearFilterButton
    },
    mixins: [validationMixin, swalFeedback, fieldsValidation],
    props: {
        handleSubmit: {
            type: Function,
            required: true,
            default: function () {},
        },
    },
    data() {
        return {
            i18nKey: i18nKey,
            i18nCommon: i18nCommon,
            filterModal: {},
            submitLoading: false,
            statuses: [
                {
                    value: true,
                    text: this.getI18n(i18nCommon, 'yes')
                },
                {
                    value: false,
                    text: this.getI18n(i18nCommon, 'no'),
                },
            ],
            parameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            valueConsistsOf: 'LEAF_PRIORITY',
            defaultCategory: { id: null, name: '', parent_id: null },
            money: {
                decimal: this.getI18n('CURRENCY.decimal'),
                thousands: this.getI18n('CURRENCY.thousands'),
                prefix: this.getI18n('CURRENCY.prefix'),
                suffix: '',
                precision: 2,
                masked: false,
            },
        }
    },
    validations: {},
    computed: {
        i18nCategory() {
            return this.getI18n(i18nCommon, 'category')
        },
        i18nBrand() {
            return this.getI18n('BRANDS', 'TITLES.brand')
        },
        i18nIsCompetitor() {
            return this.getI18n(i18nKey, 'FIELDS.competitor')
        },
        i18nIsCategoryLeader() {
            return this.getI18n(i18nKey, 'FIELDS.category_leader')
        },
        i18nActive() {
            return this.getI18n(i18nCommon, 'active')
        },
        i18nDueDate() {
            return this.getI18n(i18nKey, 'FIELDS.due_date')
        },
        i18nMinPrice() {
            return this.getI18n(i18nKey, 'FIELDS.min_price')
        },
        i18nMaxPrice() {
            return this.getI18n(i18nKey, 'FIELDS.max_price')
        },
        i18nMaxPriceFrom() {
            return `${this.i18nMaxPrice} (${this.getI18n(i18nCommon,'from')})`
        },
        i18nMaxPriceTo() {
            return `${this.i18nMaxPrice} (${this.getI18n(i18nCommon, 'to')})`
        },
        i18nMinPriceFrom() {
            return `${this.i18nMinPrice} (${this.getI18n(i18nCommon, 'from')})`
        },
        i18nMinPriceTo() {
            return `${this.i18nMinPrice} (${this.getI18n(i18nCommon, 'to')})`
        },
    },
    methods: {
        showModal(previousFilters) {
            this.filterModal = cloneObject(previousFilters);
            this.submitLoading = false
            this.$refs.formModal.show()
        },
        cleanModal() {
            this.filterModal = Object.assign(this.filterModal, {
                brands: [],
                product_category_id: null,
                isCompetitor: false,
                minPriceFrom: 0,
                minPriceTo: 0,
                maxPriceFrom: 0,
                maxPriceTo: 0,
                dueDate: null,
                isCategoryLeader: false,
                active: false,
            })
        },
        handleHide() {
            if (!this.submitLoading) {
                this.cleanModal();
            }
        },
        handleOk() {
            if (this.submitLoading) {
                return;
            }

            this.submitLoading = true;
            this.$props.handleSubmit(this.filterModal);
            this.$refs.formModal.hide()
        },
        async handleClearFilter() {
            this.cleanModal()
        }
    },
}
</script>
